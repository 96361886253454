<template>
  <div class="columns">
    <div class="column is-12">
      <v-main>
        <v-container fluid>

          <div class="back-button text-right mb-6">
            <v-btn icon @click="goBack">
              <v-icon color="grey darken-2">mdi-chevron-down</v-icon>
            </v-btn>
          </div>

          <v-container v-if="orders && orders.length === 0" class="mt-6">
            <v-row justify="center" align="center">
              <v-col cols="12" md="8">
                <div class="text-center">
                  <h2 class="title is-3 mb-2">У вас пока нет заказов</h2>
                  <b-button @click="$router.push('/main')" type="is-success is-light">
                    Создать заказ
                  </b-button>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <v-container v-else>
            <v-row v-for="(item, index) in orders" :key="item.id">
              <v-card @click="goToOrder(item.id)" :variant="variant" :class="'w-100 mb-3 no-gradient-block-' + (index % 4 + 1)">
                  <v-card-text>
                      <div class="pa-3 pb-0">
                          <v-row>
                            <v-col class="pa-0">
                              <h1 class="title is-6 ml-3">{{  dateText(item.time) }}</h1>
                            </v-col>
                            <v-col class="pa-0 tags_col">
                              <b-taglist>
                                <b-tag rounded v-if="item.status === 'created'" type="is-primary">Создан</b-tag>
                                <b-tag rounded v-else-if="item.status === 'agreed'" type="is-info">Подтвержден</b-tag>
                                <b-tag rounded v-else-if="item.status === 'completed'" type="is-success">Доставлен</b-tag>
                                <b-tag rounded v-else-if="item.status === 'in_progress'" type="is-warning">В пути</b-tag>
                                <b-tag rounded v-else-if="item.status === 'cancelled'" type="is-danger">Отменен</b-tag>
                              </b-taglist>
                            </v-col>
                          </v-row>
                          <v-row class="pt-2">
                              <v-col cols="2" class="column is-3">
                                  <img src="/assets/road.svg">
                              </v-col>
                              <v-col cols="10" class="column is-9 order-route">
                                  <v-row>
                                      <h1 class="title is-5">{{  item.from_location }}</h1>
                                  </v-row>
                                  <v-row>
                                      <h1 class="title is-5 mt-2">{{  item.to_location }}</h1>
                                  </v-row>
                              </v-col>
                          </v-row>
                      </div>
                  </v-card-text>
<!--                  <v-card-actions class="pa-4 center-flex">-->
<!--                      <h1 class="title is-4 black&#45;&#45;text">{{  item.paymentAmount + ' руб.' }}</h1>-->
<!--                  </v-card-actions>-->
              </v-card>
            </v-row>
          </v-container>


        </v-container>
      </v-main>
    </div>
  </div>

</template>

<script>

export default {
  name: 'OrderList',
  data() {
    return {
      variant: 'outlined',
    };
  },
  created() {
    this.$store.dispatch('GET_ORDERS');
  },
  computed: {
    orders() {
      return this.$store.getters.ORDERS;
    },
  },
  methods: {
    dateText(date) {
      return this.$store.getters.TEXT_DATE(date);
    },
    goToOrder(id) {
      this.$router.push({ path: `/orders/${id}` }).catch(()=>{});
    },
    goBack() {
      this.$router.push('/activity-feed');
    }
  },
};
</script>

<style>
</style>
